import logo from './logo.svg';
import './App.css';
import Login from './Login';
import { Helmet } from "react-helmet";
function App() {
  // useEffect(() => {
  //   document.title = "Login";
  // }, []);
  // document.title = "Login";
  return (
    <div className="App">
      <Helmet>
        <title>Login</title>
      </Helmet>
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <Login/>
        
      </header>
    </div>
  );
}

export default App;