// import { logout } from "../AuthHook.js";
import { useAuth } from "../AuthHook";
import { Helmet } from "react-helmet";
export default () => {
  const { logout } = useAuth();
  const handleLogout = async (e) => {
    await logout();
  };
  // useEffect(() => {
  //   document.title = "Main";
  // }, []);
  // document.title = "Main";
  return (
    <div>
      <Helmet>
        <title>Main</title>
      </Helmet>
      <h1>Logged in.</h1>
      <button className="btn btn-primary" onClick={handleLogout}>Logout</button>
    </div>
  );
};