// import * as bs from "react-bootstrap";
// import "../node_modules/bootstrap/dist/css/bootstrap.min.css"
// import {
//   FormGroup,
//   FormControl
// } from "react-bootstrap"
// import "./Login.css"
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "./AuthHook";
const invoke_url =
  "https://73ib4xu9x0.execute-api.ca-central-1.amazonaws.com/api/auth";
function Login() {
  const { login } = useAuth();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const handleLogin = async (e) => {
    e.preventDefault();
    const loginbutton = document.getElementById("LOGINBUTTON");
    loginbutton.disabled = true;
    const data = {
      type: "GET_JWT",
      content: {
        username: username,
        password: password,
      },
    };
    const response = await fetch(invoke_url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    let resData = undefined;
    console.log(response);
    try {
      resData = await response.json();
    } catch (e) {
      // alert(e);
      console.log("ERROR: ", e);
    }
    if (response.ok && resData.success) {
      await login({ JWT: resData.token });
      navigate("/");
    } else {
      alert("Invalid Credentials");
      loginbutton.disabled = false;
    }
    // if (username === "admin" && password === "admincamel") {
    //   await login({ username: username });
    //   navigate("/");
    // } else {
    //   alert("Invalid Credentials");
    // };
  };
  return (
    <div>
      <form className="form-group" autoComplete="off" onSubmit={handleLogin}>
        {/* <label for="user">Username</label> */}
        <input
          className="form-control"
          id="user"
          placeholder="Username"
          onChange={(e) => setUsername(e.target.value)}
          required
        ></input>
        {/* <label for="pass">Password</label> */}
        <br></br>
        <input
          type="password"
          className="form-control"
          placeholder="Password"
          onChange={(e) => setPassword(e.target.value)}
          required
        ></input>
        <button type="submit" className="btn btn-primary" id="LOGINBUTTON">
          Login
        </button>
      </form>
    </div>
    // <form className="form-group">
    //   <label for="user">Username</label>
    //   <input id="user" className="form-control" required></input>
    //   <br></br>
    //   <label for="pass" required>Password</label>
    //   <input id="pass"></input>
    // </form>
    // <bs.FormGroup>
    //   <bs.FormControl>YO</bs.FormControl>
    // </bs.FormGroup>
  );
}

export default Login;
