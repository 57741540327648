import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import Main from "./components/Main.js";
// import reportWebVitals from './reportWebVitals';
// import Login from './Login';
// import Cookies from "js-cookie";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css"
import { ProtectedRoute } from './protectedRoute';
import { AuthProvider } from './AuthHook';
// import hooks from "@uidotdev/usehooks";

import {
  createBrowserRouter,
  RouterProvider
} from "react-router-dom";
const router = createBrowserRouter([
  {
    path: "/",
    element: <ProtectedRoute>
      <Main/>
    </ProtectedRoute>
  },
  {
    path: "/login",
    element: <App/>
  },
]);
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    {/* <App /> */}
    <AuthProvider>
      <RouterProvider router={router}/>
    </AuthProvider>
    
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();